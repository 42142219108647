import {
    osVersion,
    osName,
    isMobile,
    browserName,
    fullBrowserVersion,
    mobileModel,
    deviceType
} from 'react-device-detect';

export default function useDetectedDevice() {
    const smallInfo = () => {
        return {
            device: isMobile ? 'mob' : 'desk',
            os: `${osName} ${osVersion}`,
        }
    };

    const fullInfo = () => {
        return {
            os: `${osName} ${osVersion}`,
            browser: `${browserName} ${fullBrowserVersion}`,
            mobile: mobileModel,
            device: deviceType
        }
    };

    return {
        smallInfo, fullInfo
    }
}