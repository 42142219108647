import React from 'react';
import styles from '../styles.module.scss';

export default ({changeBlock, btnName1, btnName2}) => {
    return (
        <div className={styles.page_quest_buttons_row}>
            <div className={`${styles.page_yes_btn} ${styles.btn} ${styles.fill}`} onClick={changeBlock}>{btnName1}</div>
            <div className={`${styles.page_skip_btn} ${styles.btn} ${styles.stroke}`} onClick={changeBlock}>{btnName2}</div>
        </div>
    )
}
