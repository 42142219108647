import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import LoginApi from '../../services/api/LoginApi';
import {checkEmail, CheckEmailAPI, getUrlParameter, injectParam} from '../../services/methods';

import * as SOCIAL from '../../constants/social';
import * as DATE from '../../constants/date';

// import useGetLandingName from '../useGetLandingName';
import useSocialGoogle from "../social/useSocialGoogle";
import useAddTail from "../useAddTail";
import useSaveTrafficConfig from "../traffic/useSaveTrafficConfig";

export default function useInitLndBlock(trafficConfig = null) {
    const history = useHistory();
    // const lndName = useGetLandingName();
    const lndName = '';
    const {responseGoogle} = useSocialGoogle();
    const addTail = useAddTail();
    const saveTrafficConfig = useSaveTrafficConfig();

    const initStateFormData = {
        age: '',
        name: '',
        email: '',
        password: '',
        year: 0
    };

    const [formData, setFormData] = useState(initStateFormData);
    const [currentBlock, setCurrentBlock] = useState(1);
    const [modalIsActiveLoader, setModalIsActiveLoader] = useState(false);
    const [warningText, setWarningText] = useState('');
    const [cookiesBannerActive, setCookiesBannerActive] = useState(true);

    const closeCookiesBannerActive = () => setCookiesBannerActive(false);

    const doSetCurrentBlock = (num) => {
        const pathname = window?.location?.pathname;

        let newPathname;

        if (pathname?.endsWith('block')) newPathname = pathname + num;
        else newPathname = pathname?.substring(0, pathname?.length - 1) + num;

        history.push(newPathname + window?.location?.search?.substring());
        setCurrentBlock(num);

        return newPathname;
    };

    const checkValidEmailAndPassword = () => {
        if (!formData?.email?.length || !formData?.password?.length) {
            setWarningText('These fields are required.');
            return false
        } else if (!checkEmail(formData?.email)) {
            setWarningText('Invalid email.');
            return false
        } else if (formData?.password?.length < 6) {
            setWarningText('Password must be at least 6 characters.');
            return false
        }

        setWarningText('');
        return true;
    };

    const changeBlock = (num) => doSetCurrentBlock(+num);

    const saveIndexedHistory = (key, value, index) => {
        const pahtname = window?.location?.pathname;
        let newPathname;

        if (pahtname?.endsWith('block')) newPathname = pahtname + currentBlock;
        else newPathname = pahtname?.substring(0, pahtname?.length - 1) + currentBlock;

        saveIndexedHistoryWithPath(newPathname, key, value, index);
    };

    const saveIndexedHistoryWithPath = (path, key, value, index) => {
        const param = key + '=' + value;
        const params = injectParam(window?.location?.search?.substring(), param, index);

        history.push(path + '?' + params);
    };

    const handleInputUserAge = (value) => {
        if (value?.trim().length <= 2 && Number.isInteger(+value)) setFormData({...formData, ...{age: value?.trim()}});
        if (+value >= DATE.MIN_AGE) setWarningText('');
    };

    const setFormDataByDMY = (value, index) => {
        if (index === 'dd' || index === 'mm' || index === 'yyyy') {
            if (isNaN(+value)) return;
            setFormData({...formData, ...{[index]: +value}});
        }
    }

    const setUserAgeByDMY = () => {
        if (+formData?.dd > 0 && +formData?.mm > 0 && +formData?.yyyy > 0) {
            if (!(+formData?.dd > 0 && +formData?.dd <= 31) || !(+formData?.mm > 0 && +formData?.mm <= 12) || !(+formData?.yyyy > 1900 && +formData?.yyyy <= DATE.CURRENT_YEAR)) {
                setWarningText('Invalid date.');
                return false;
            }

            const age = moment().diff(`${+formData?.yyyy}-${+formData?.mm}-${+formData?.dd}`, 'years');

            if (!age) {
                setWarningText('Invalid date.');
                return false;
            } else if (+age < DATE.MIN_AGE) {
                setWarningText(`You must be at least ${DATE.MIN_AGE} years old.`);
                return false;
            }

            setWarningText('');
            setFormData({...formData, ...{year: +formData?.yyyy}});
            addTail('utm_real_age', age);
            return true;
        } else {
            setWarningText('These fields are required.');
            return false;
        }
    };

    const setUserAge = () => {
        if (!formData?.age) {
            setWarningText('This field is required.');
            return false;
        } else if (+formData?.age < DATE.MIN_AGE) {
            setWarningText(`You must be at least ${DATE.MIN_AGE} years old.`);
            return false;
        }

        saveTrafficConfig(trafficConfig, formData?.age, () => {
            const now = new Date();
            const userYearOfBirth = now?.getFullYear() - +formData?.age;

            setFormData({...formData, ...{year: userYearOfBirth}});
            addTail('utm_real_age', formData?.age);
            changeBlock(7);
        });
    };

    const setUserName = () => {
        if (!formData?.name?.trim()?.length) {
            setWarningText('This field is required.');
            return false;
        }

        changeBlock(8);
    };

    const handleInputUserName = (value) => {
        setFormData({...formData, ...{name: value}});
        if (value?.length >= 1) setWarningText('');
    };

    const handleInputUserEmail = (value) => {
        setFormData({...formData, ...{email: value}});
        if (value?.length && formData?.password?.length) setWarningText('');
    };

    const handleInputUserPassword = (value) => {
        setFormData({...formData, ...{password: value}});
        if (value?.length && formData?.email?.length) setWarningText('');
    };

    const onSubmit = async () => {
        if (!checkValidEmailAndPassword()) return false;

        const isEmailInUse = (await CheckEmailAPI(formData?.email))?.status;

        if (isEmailInUse === undefined) {
            setWarningText('Something went wrong.');
            return false;
        } else if (isEmailInUse) {
            setWarningText('This email is in use.');
            return false;
        }

        setWarningText('');
        setModalIsActiveLoader(true);

        new LoginApi()
            .postRegister(
                formData?.email,
                formData?.password,
                +formData?.dd > 0 ? +formData?.dd : 1,
                +formData?.mm > 0 ? +formData?.mm : 1,
                formData?.year,
                2, formData?.name,
                0, DATE.MIN_AGE, DATE.MAX_AGE, 0
            )
            .then(res => {
                if (res?.status && res?.token) {
                    const redirectURLList = ['chat', 'search', 'letter', 'likeher', 'videoshow'];
                    const redirectURL = getUrlParameter('redirect_url');
                    const queryURL = `${window.location.search}&registration=success&callback=` + btoa(res.token);

                    try {
                        window.dataLayer.push({ 'event': 'sof', 'eventCategory': 'send', 'eventAction': 'form', 'eventLabel': 'reg' });
                    } catch (e) {
                        console.warn(e);
                    }

                    localStorage.setItem('token', JSON.stringify(res.token));

                    if (!redirectURL) window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/search${queryURL}`;
                    else if (!isNaN(redirectURL)) window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/profile-woman/${redirectURL}${queryURL}`;
                    else if (redirectURLList.includes(redirectURL.toLowerCase())) window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/${redirectURL.toLowerCase()}${queryURL}`;
                    else window.location = `${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/search${queryURL}`;
                } else {
                    alert('Registration failed, please try again.');
                    window.location.href = `${lndName?.length ? `/${lndName}` : ''}`;
                }
            })
    };

    useEffect(() => {
        if (!window.location.search.includes('utm_unik')) {
            axios.get(`${SOCIAL.PATH_MAIN_DOMAIN ? SOCIAL.PATH_MAIN_DOMAIN : ''}/api/login/checkCountIp`).then(res => {
                let unikParam = '';

                if (Number(res?.data?.count) === 0) unikParam = 'utm_unik=true';
                else unikParam = 'utm_unik=false';

                const params = injectParam(window?.location?.search?.substring(1), unikParam, 0);

                history.push(`${lndName?.length ? `/${lndName}` : ''}/block` + currentBlock + '?' + params);
            });
        } else {
            doSetCurrentBlock(1);
        }

        const params = window?.location?.search?.substring()?.substring();
        if (!history.location.pathname.includes('/block')) {
            history.push(`${lndName?.length ? `/${lndName}` : ''}/block1` + params);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return {
        currentBlock, setCurrentBlock,
        changeBlock,
        formData,
        handleInputUserAge,
        setUserAge,
        warningText,
        setUserName,
        handleInputUserName,
        handleInputUserEmail,
        handleInputUserPassword,
        onSubmit,
        modalIsActiveLoader,
        saveIndexedHistory,
        setWarningText,

        cookiesBannerActive,

        addTail, responseGoogle, closeCookiesBannerActive, setUserAgeByDMY, setFormDataByDMY,
    }
}
