import React from "react";

import styles from '../styles.module.scss';
import img from "../../../img/lnd31";

export default ({changeBlock}) => {
    return (
        <>
            <div className={styles.page_bg_wrap_anim}>
                <div className={`${styles.anim_item} ${styles.big} ${styles.i1}`}>
                    <img src={img.images['it1.jpg']} alt=""/>
                    <div className={styles.anim_item_name}>Svetlana, 30</div>
                </div>
                <div className={`${styles.anim_item} ${styles.small} ${styles.i2}`}>
                    <img src={img.images['it2.jpg']} alt=""/>
                    <div className={styles.anim_item_name}>Marina, 31</div>
                </div>
                <div className={`${styles.anim_item} ${styles.middle} ${styles.i3}`}>
                    <img src={img.images['it3.jpg']} alt=""/>
                    <div className={styles.anim_item_name}>Alena, 25</div>
                </div>
                <div className={`${styles.anim_item} ${styles.big} ${styles.i4}`}>
                    <img src={img.images['it4.jpg']} alt=""/>
                    <div className={styles.anim_item_name}>Alena, 27</div>
                </div>
                <div className={`${styles.anim_item} ${styles.small} ${styles.i5}`}>
                    <img src={img.images['it5.jpg']} alt=""/>
                    <div className={styles.anim_item_name}>Yana, 27</div>
                </div>
                <div className={`${styles.anim_item} ${styles.middle} ${styles.i6}`}>
                    <img src={img.images['it6.jpg']} alt=""/>
                    <div className={styles.anim_item_name}>Oksana, 28</div>
                </div>
                <div className={`${styles.anim_item} ${styles.big} ${styles.i7}`}>
                    <img src={img.images['it7.jpg']} alt=""/>
                    <div className={styles.anim_item_name}>Kristina, 30</div>
                </div>
                {/*<div className={`${styles.anim_item} ${styles.small} ${styles.i8}`}>*/}
                {/*    <img src={img.images['it8.jpg']} alt=""/>*/}
                {/*    <div className={styles.anim_item_name}>Inna, 27</div>*/}
                {/*</div>*/}
                <div className={`${styles.anim_item} ${styles.middle} ${styles.i9}`}>
                    <img src={img.images['it9.jpg']} alt=""/>
                    <div className={styles.anim_item_name}>Evgenia, 33</div>
                </div>
                <div className={`${styles.anim_item} ${styles.big} ${styles.i10}`}>
                    <img src={img.images['it10.jpg']} alt=""/>
                    <div className={styles.anim_item_name}>Olga, 28</div>
                </div>
                {/*<div className={`${styles.anim_item} ${styles.small} ${styles.i11}`}>*/}
                {/*    <img src={img.images['it11.jpg']} alt=""/>*/}
                {/*    <div className={styles.anim_item_name}>Olga, 36</div>*/}
                {/*</div>*/}
                <div className={`${styles.anim_item} ${styles.middle} ${styles.i12}`}>
                    <img src={img.images['it12.jpg']} alt=""/>
                    <div className={styles.anim_item_name}>Aleksandra, 25</div>
                </div>
                <div className={`${styles.anim_item} ${styles.big} ${styles.i13}`}>
                    <img src={img.images['it13.jpg']} alt=""/>
                    <div className={styles.anim_item_name}>Elena, 28</div>
                </div>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
                <li/>
            </div>

            <div className={`${styles.page_block_1} ${styles.block}`}>
                <div className={styles.page_big_title}>True Love is Few Clicks Away!</div>
                <div className={styles.page_start_text}>Quick wit and beauty of our members will leave you speechless.
                    Chat with single women now!
                </div>
                <div className={styles.page_start_text}>Dating for men over 45!</div>
                <div className={`${styles.page_start_btn} ${styles.btn} ${styles.fill}`} onClick={changeBlock}>FIND LOVE
                    NOW
                </div>
                <div className={styles.page_start_reg_text}>
                    <span>Free registration!</span><br/>
                    no credit card required
                </div>
            </div>
        </>

    )
}