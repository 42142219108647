import React from "react";

import styles from '../styles.module.scss';

export default ({userAge, handleInputUserAge, setUserAge, warningText}) => {
    return (
        <div className={styles.block_wrap}>
            <div className={`${styles.page_block_2} ${styles.block}`}>
                <div className={styles.page_quest_title}>Registration 1/3</div>
                <div className={styles.page_reg_text}>Understanding your real age will help us to find best matches
                    exactly for you
                </div>
                <div className={styles.page_reg_input_row}>
                    <div className={styles.page_reg_box_input}>
                        <input
                            className={`${styles.page_reg_input} ${styles.input_num} ${!!warningText?.length ? styles.error : ''}`}
                            value={userAge}
                            type="text"
                            placeholder="Enter your age here..."
                            onChange={(e) => handleInputUserAge(e.currentTarget.value)}
                        />
                        {!!warningText?.length && <span>{warningText}</span>}
                    </div>
                    <div className={`${styles.page_yes_btn} ${styles.btn} ${styles.fill}`} onClick={setUserAge}>NEXT
                    </div>
                </div>
            </div>
        </div>
    )
}