import React, {useEffect} from "react";

import styles from '../styles.module.scss';

export default ({
                    userName,
                    handleInputUserName,
                    warningText,
                    setWarningText,
                    setUserName
                }) => {
    useEffect(() => {
        return () => setWarningText('');
    }, []);//eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={styles.block_wrap}>
            <div className={`${styles.page_block_2} ${styles.block}`}>
                <div className={styles.page_quest_title}>Registration 2/3</div>
                <div className={styles.page_reg_text}>Please enter your name</div>
                <div className={`${styles.page_reg_input_wrap} ${styles.input_name}`}>
                    <input
                        className={`${styles.page_reg_input} ${!!warningText?.length ? styles.error : ''}`}
                        type="text"
                        placeholder="Enter your name here..."
                        value={userName}
                        onChange={(e) => handleInputUserName(e.currentTarget.value)}
                    />
                    {!!warningText?.length && <span>{warningText}</span>}
                </div>
                <div className={`${styles.page_find_btn} ${styles.btn} ${styles.fill}`} onClick={setUserName}>NEXT</div>
            </div>
        </div>
    )
}