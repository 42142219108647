import React from 'react';
import Modal from "react-modal";
import * as IMAGES from "../TermsModal/img";

export default ({modalIsActive, closeModal}) => {
    return (
        <Modal
            style={{
                overlay: {
                    left: '0',
                    top: '0',
                    width: '100%',
                    height: '100vh',
                    backgroundColor: 'rgba(55,78,108,0.32)',
                    zIndex: 99999,
                    backdropFilter: 'blur(4px)',
                },
                content: {
                    width: 'calc(100% - 30px)',
                    height: 'calc(100% - 30px)',
                    top: '15px',
                    left: '15px',
                    right: 0,
                    bottom: 0,
                    border: 'none',
                    background: 'rgb(255, 255, 255, 0) !important',
                }
            }}
            isOpen={modalIsActive}
            onRequestClose={closeModal}
            ariaHideApp={false}
        >
            <img src={IMAGES.loading} alt={'Loading'} className="loader"/>
        </Modal>
    )
}