import React, {useState} from 'react';

import styles from './styles.module.scss'
import '../../styles/lnd31/styles.scss'
import '../../styles/lnd31/main.css';
import img from "../../img/lnd31";

import Header from './Header';
import Block1 from './Block1';
import Block2 from './Block2';
import Block3 from './Block3';
import Block4 from './Block4';
import Block5 from './Block5';
import Block6 from './Block6';
import Block7 from './Block7';
import Block8 from './Block8';
import TermsModal from '../../components/common/Modals/TermsModal';
import LoaderModal from '../../components/common/Modals/LoaderModal';

import useCheckValidToken from '../../hooks/useCheckValidToken';
import useInitLndBlock from '../../hooks/lnd30/useInitLndBlock';
import useInitTerm from '../../hooks/useInitTerm';
import useSocialGoogle from '../../hooks/social/useSocialGoogle';
import * as DATE from "../../constants/date";
import useGetTrafficConfig from "../../hooks/traffic/useGetTrafficConfig";

export default () => {
    useCheckValidToken();
    const getTrafficConfig = useGetTrafficConfig();

    const {
        changeBlock,
        formData,
        handleInputUserAge,
        setUserAge,
        warningText,
        setWarningText,
        setUserName,
        handleInputUserName,
        handleInputUserEmail,
        handleInputUserPassword,
        onSubmit,
        modalIsActiveLoader,
        saveIndexedHistory
    } = useInitLndBlock(getTrafficConfig?.trafficConfig);

    const initTerm = useInitTerm();
    const {responseGoogle} = useSocialGoogle();

    const [cookiesBannerActive, setCookiesBannerActive] = useState(true);

    const locationCurrentBlock = window?.location?.pathname?.includes('block') ? window?.location?.pathname?.slice(-1) : '';

    const activeBlock = () => {
        if (+locationCurrentBlock === 1) return <Block1 changeBlock={() => changeBlock(2)}/>;
        else if (+locationCurrentBlock === 2) return <Block2 changeBlock={() => changeBlock(3)}/>;
        else if (+locationCurrentBlock === 3) return <Block3 changeBlock={() => changeBlock(4)}/>;
        else if (+locationCurrentBlock === 4) return <Block4 changeBlock={() => changeBlock(5)}/>;
        else if (+locationCurrentBlock === 5) return <Block5 changeBlock={() => changeBlock(6)}/>;
        else if (+locationCurrentBlock === 6) return <Block6
            userAge={formData?.age}
            handleInputUserAge={handleInputUserAge}
            setUserAge={setUserAge}
            warningText={warningText}
        />;
        else if (+locationCurrentBlock === 7) return <Block7
            userName={formData?.name}
            handleInputUserName={handleInputUserName}
            warningText={warningText}
            setWarningText={setWarningText}
            setUserName={setUserName}
        />;
        else if (+locationCurrentBlock === 8) return <Block8
            userEmail={formData?.email}
            handleInputUserEmail={handleInputUserEmail}
            userPassword={formData?.password}
            handleInputUserPassword={handleInputUserPassword}
            warningText={warningText}
            setWarningText={setWarningText}
            onSubmit={onSubmit}
        />;
    };

    return (
        <>
            <div className={styles.page_content}>
                {
                    (+locationCurrentBlock !== 1)
                    && <div className={styles.video_bg_wrap}>
                        <video preload loop autoPlay={true} className={styles.video_bg_player}>
                            <source src={img.videos['bg_m.mp4']} type="video/mp4"/>
                            <source src={img.videos['bg_m.webm']} type="video/webm"/>
                            Your browser doesn't support HTML5 video tag.
                        </video>
                        <div className={`${styles.video_bg_blur} ${styles[`bl_${locationCurrentBlock}`]}`}/>
                    </div>
                }
                <div className={styles.page_header}>
                    <Header saveIndexedHistory={saveIndexedHistory} responseGoogle={responseGoogle}/>
                    {
                        (+locationCurrentBlock !== 1)
                        && <div className={styles.steps_wrap}>
                            <div className={`${styles.step_item} ${styles.step_1} ${(+locationCurrentBlock === 2) ? styles.active : ""}`}>
                                <span>Step </span>1
                                <svg width="17" height="40" viewBox="0 0 17 40" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 2L15 20L2 38" strokeWidth="4" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className={`${styles.step_item} ${styles.step_1} ${(+locationCurrentBlock === 3) ? styles.active : ""}`}>
                                <span>Step </span>2
                                <svg width="17" height="40" viewBox="0 0 17 40" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 2L15 20L2 38" strokeWidth="4" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className={`${styles.step_item} ${styles.step_1} ${(+locationCurrentBlock === 4) ? styles.active : ""}`}>
                                <span>Step </span>3
                                <svg width="17" height="40" viewBox="0 0 17 40" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 2L15 20L2 38" strokeWidth="4" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className={`${styles.step_item} ${styles.step_1} ${(+locationCurrentBlock === 5) ? styles.active : ""}`}>
                                <span>Step </span>4
                                <svg width="17" height="40" viewBox="0 0 17 40" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 2L15 20L2 38" strokeWidth="4" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className={`${styles.step_item} ${styles.step_1} ${(+locationCurrentBlock === 6) ? styles.active : ""}`}>
                                <span>Step </span>5
                                <svg width="17" height="40" viewBox="0 0 17 40" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 2L15 20L2 38" strokeWidth="4" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className={`${styles.step_item} ${styles.step_1} ${(+locationCurrentBlock === 7) ? styles.active : ""}`}>
                                <span>Step </span>6
                                <svg width="17" height="40" viewBox="0 0 17 40" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 2L15 20L2 38" strokeWidth="4" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                </svg>
                            </div>
                            <div className={`${styles.step_item} ${styles.step_7} ${(+locationCurrentBlock === 8) ? styles.active : ""}`}>
                                <span>Step </span>7
                            </div>
                        </div>
                    }
                </div>
                {activeBlock()}
                <div className={styles.page_copy}>© {DATE.CURRENT_YEAR} AdatIngWebsite.com All rights reserved &nbsp;<span>
                    <a href="#terms-and-conditions" onClick={() => initTerm.openTermModal('Terms and Conditions')}>Terms and Conditions</a>
                    </span> | <span><a href="#privacy-policy" onClick={() => initTerm.openTermModal('Privacy Policy')}> Privacy Policy</a></span>
                </div>
                {
                    !!cookiesBannerActive &&
                    <div className={styles.page_policy}>
                        <div className={styles.page_policy_text}>By continuing to use our site you <br/> are
                            agreeing to
                            our
                            cookie policy
                        </div>
                        <div className={styles.page_policy_link}>
                            <a href="#cookie-policy" onClick={() => initTerm.openTermModal('Cookie Policy')}> Learn
                                more</a>
                        </div>
                        <div className={styles.page_policy_close} onClick={() => setCookiesBannerActive(false)}>
                            <img src={img.images['cross.svg']} alt=""/>
                        </div>
                    </div>
                }
            </div>
            <TermsModal {...initTerm}/>
            <LoaderModal modalIsActive={modalIsActiveLoader}/>
        </>
    );
}
;
