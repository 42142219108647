import React from 'react';
import styles from "../styles.module.scss";
import img from "../../../img/lnd31";

export default () => {
    return (
        <div className={styles.page_header_top}>
            <img className={styles.header_logo} src={img.images['logo.svg']} alt=""/>
        </div>
    )
}