import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {getLandingPageComponent} from "../services/methods/routes";

export const Router = () => {
    return (
        <main className="content">
            <Switch>
                <Route exact path={`/`} component={getLandingPageComponent()}/>
                <Route exact path={`/:block`} component={getLandingPageComponent()}/>
            </Switch>
        </main>
    )
};
