export const LND31 = 'lnd31';

// TRAFFIC ROUTE
export const LND31T = 'lnd31t';

export const ROUTE_LIST_TRAFFIC = [LND31T];

export const ROUTE_LIST = [
    LND31,
];

export const ROUTE_SOURCE_MAP = {
    [LND31]: [LND31, LND31T],
};